import { Component } from 'react';
import { throttle, findLast } from 'lodash';

class ResizeAction extends Component {

  constructor(props) {
    super(props)

    this.state = {
      currentViewport: 'default'
    }

    this.sizes = [
      {
        'name': 'mobile',
        'min': 0
      },
      {
        'name': 'tablet',
        'min': 640
      },
      {
        'name': 'desktop',
        'min': 1024
      },
      {
        'name': 'xlarge',
        'min': 1800
      }
    ]

    // this.sizes = {
    //   'mobile': 0,
    //   'tablet': 640,
    //   'desktop': 1024,
    //   'xlarge': 1800
    // }

    this.setViewport = this.setViewport.bind(this);

  }

  onResize = throttle(this.setViewport, 200).bind(this);

  componentDidMount() {

    this.setViewport();
    window.addEventListener("resize", this.onResize);

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  setViewport() {

    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    let size = findLast(this.sizes, (o) => {
      return windowWidth > o.min
    })

    let viewport = size.name;

    if (this.state.currentViewport !== viewport) {

      console.log('change to ' + viewport);

      let funcName = 'on' + viewport.charAt(0).toUpperCase() + viewport.slice(1)

      if(this.props.hasOwnProperty(funcName)) {
        this.props[funcName]()
      }
    }
    this.setState({
      currentViewport: viewport
    })

  }

  render () {
    return null
  }
}

export default ResizeAction;
