import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import './Sets.scss';

class Sets extends Component {

  render () {
    return (
      <div className="m-set__wrapper">

        {this.props.comps.map( (comp, i) => {
          return (
            <div key={i} className={"m-set__comp slide-count-" + comp.slides.length} >
              <Link to={this.props.slidesPagePath + comp.id}>
              <span className="m-set__id">{comp.id}</span>
              {comp.slides.map((slide, j) => (
                <span key={j} className="m-set__image">
                  <img src={slide.thumb} alt={slide.title}/>
                  {/*<p>{slide.title}</p>*/}
                </span>
              ))}
              </Link>
            </div>
          )
        })}

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    comps: state.items,
    hasErrored: state.itemsHasErrored,
    isLoading: state.itemsIsLoading
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(Sets);
