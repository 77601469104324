import React, { Component } from 'react';
import { connect } from 'react-redux';
import { menuToggled } from '../../actions/menu';
import './MobileNavToggler.scss';

class MobileNavToggler extends Component {

  render () {
    return (
      <div className="m-mobileNavToggler" onClick={this.props.onClick}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => {
      dispatch(menuToggled())
    }
  };
};

export default connect(null, mapDispatchToProps)(MobileNavToggler);
