import React, { Component } from 'react';
import Slick from 'react-slick';
import { createBrowserHistory } from "history";
import { connect } from 'react-redux';
import OrientationState from 'components/orientationState/OrientationState';
import './Slider.scss'
import '../../styles/_shared.scss'

// https://react-slick.neostack.com/docs/example/custom-arrows

class Slider extends Component {

  constructor(props) {
    super(props)

    this.caption = React.createRef();

    this.setUrl = this.setUrl.bind(this)
    this.updateCount = this.updateCount.bind(this)
    this.setCaption = this.setCaption.bind(this)
    this.advanceSlide = this.advanceSlide.bind(this)

    this.defaults = {
      accessibility: true,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      onInit: this.updateCount,
      afterChange: this.setUrl,
      beforeChange: this.setCaption
      // swipeToSlide: true
    }

    // this.hasInitialized = false

    this.state = {
      currentSlideCount: 10,
      hasInitialized: false
    }

    this.history = createBrowserHistory();

    this.history.listen( (location, action) =>  {
      // console.log(location.pathname)
      // console.log(this.props.location.state)
    });

  }

  componentDidMount (prevProps) {

    // console.log('mount');

    if (this.props.comps.length && (this.options.initialSlide === 0 || this.options.initialSlide === -1) ) {
      this.setUrl(0)
    }

    document.addEventListener('keydown', this.advanceSlide);

  }

  componentDidUpdate () {

    // console.log('update');

  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.advanceSlide);
  }

  advanceSlide(e) {
    let keyCode = e.keyCode;
    if(keyCode === 37) {
      this.prevSlide();
    } else if (keyCode === 39) {
      this.nextSlide()
    }
  }

  updateCount() {
    let slideData = this.props.comps[this.props.options.initialSlide];
    if (slideData) {
      this.setState({
        currentSlideCount: slideData.count,
        hasInitialized: true
      })
    }
    setTimeout( () => {
      this.setCaption(this.props.options.initialSlide)
    }, 100)
  }

  setCaption(index) {

    let slide = this.props.comps[index];

    let txt = slide.caption ? slide.caption : ((slide.count > 1) ? slide.count : '') + " untitled photograph" + ((slide.count > 1) ? 's' : '') + ", 2001 - ongoing"

    this.caption.current.innerHTML = txt;

  }

  setUrl(index) {
    let slideData = this.props.comps[index];
    this.setState({
      currentSlideCount: slideData.count
    })
    this.history.push(this.props.slidesPagePath + slideData.id)

    this.setCaption(index);
  }

  nextSlide() {
    this.slider.slickNext();
  }


  prevSlide() {
    this.slider.slickPrev();
  }

  render () {
    if (!this.props.comps.length) {
      return ''
    } else {
      this.options = {...this.defaults, ...this.props.options}
    }
    return (
      <OrientationState target=".slick-active .slick-slide-wrapper .slick-slide-image-wrapper">
        <div className="m-slides" onClick={this.nextSlide.bind(this)}>
          <Slick ref={slider => (this.slider = slider)} {...this.options}>
            {this.props.comps.map( (comp, i) =>

              <div
                key={i}
                data-id={comp.id}
                data-count={comp.count}
                className={"slick-slide-wrapper slide-count-" + comp.slides.length + " layout-" + comp.layout }
                // onClick={this.nextSlide.bind(this)}
              >
                {comp.slides.map((slide, j) =>

                    <div key={j} className="slick-slide-image-wrapper">
                      <img src={slide.src} alt='test'/>
                    </div>
                )}

              </div>

            )}
          </Slick>
          <div className="m-caption" ref={this.caption}></div>
        </div>
      </OrientationState>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(ownProps);
  return {
    options : {
      ...ownProps.options,
      initialSlide: (ownProps.match) ? parseInt(ownProps.comps.findIndex(comp => comp.id === ownProps.match)) : 0
    }
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(Slider);