export function contextHasErrored(state = false, action) {
    switch (action.type) {
        case 'CONTEXT_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function contextIsLoading(state = false, action) {
    switch (action.type) {
        case 'CONTEXT_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function context(state = [], action) {
    switch (action.type) {
        case 'CONTEXT_FETCH_DATA_SUCCESS':
            return action.context;

        default:
            return state;
    }
}
