import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Index.scss';

class Index extends Component {

  render () {
    return (
      <div className="m-index__wrapper">

        {this.props.comps.map( (comp) => {
          return (
            comp.slides.map((slide, i) => (
              <span key={i} className="m-index__image">
                  <img src={slide.src} alt={slide.title}/>
                  <p>{slide.title}</p>
                </span>
            ))
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    comps: state.items,
    hasErrored: state.itemsHasErrored,
    isLoading: state.itemsIsLoading
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(Index);
