export function contextHasErrored(bool) {
    return {
        type: 'CONTEXT_HAS_ERRORED',
        hasErrored: bool
    };
}

export function contextIsLoading(bool) {
    return {
        type: 'CONTEXT_IS_LOADING',
        isLoading: bool
    };
}

export function contextFetchDataSuccess(context) {
    return {
        type: 'CONTEXT_FETCH_DATA_SUCCESS',
        context
    };
}

export function contextFetchData(url) {
    return (dispatch) => {
        dispatch(contextIsLoading(true));

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                dispatch(contextIsLoading(false));

                return response;
            })
            .then((response) => response.json())
            .then((context) => {
                dispatch(contextFetchDataSuccess(context.data[0]))

            })
            .catch(() => dispatch(contextHasErrored(true)));
    };
}
