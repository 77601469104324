import { combineReducers } from 'redux';
import { items, itemsHasErrored, itemsIsLoading, menuToggled } from './items';
import { context, contextHasErrored, contextIsLoading } from './context';

export default combineReducers({
    context,
    items,
    itemsHasErrored,
    itemsIsLoading,
    menuOpen: menuToggled
});

