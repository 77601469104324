import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './App.scss';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect, Switch } from "react-router-dom";
import { menuToggled } from '../../actions/menu';

import Slider from 'components/slider/Slider';
import SlideNav from 'components/slideNav/SlideNav';
import Info from 'components/info/Info';
import Index from 'components/index/Index';
import List from 'components/list/List';
import Prelude from 'components/prelude/Prelude';
import Sets from 'components/sets/Sets';
import MobileNavToggler from 'components/mobileNavToggler/MobileNavToggler';

import ResizeAction from '../resizeAction/ResizeAction';

import Loader from 'react-loaders'

import { itemsFetchData } from '../../actions/items';
import { contextFetchData } from '../../actions/context';

class App extends Component {

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  nextSlide() {
    this.slider.current.getWrappedInstance().nextSlide();
  }

  prevSlide() {
    this.slider.current.getWrappedInstance().prevSlide();
  }

  componentDidMount() {
    this.props.fetchContext('http://art-craft.gregsimsic.com/slides/context.json');
    this.props.fetchItems('http://art-craft.gregsimsic.com/slides/group-1.json');
    // this.props.fetchItems('http://10.0.1.4:80/slides/group-1.json');
  }

  render() {

    if (this.props.hasErrored) {
      return <p>There was an error loading the site. Please try <a href="/">reloading the page</a>.</p>;
    }

    // alternative: https://mhnpd.github.io/react-loader-spinner/?selectedKind=Loader&selectedStory=Oval&full=0&addons=0&stories=1&panelRight=0
    if (this.props.isLoading) {
      return <Loader type="ball-triangle-path" />
    }

    return (
      <Router>
        <div className={(this.props.menuOpen) ? "App s-menu-open" : "App"}>
          <ResizeAction onDesktop={this.props.closeMenu} />

          <Route exact path="*" render={({ location, history }) =>
            <header className="App-header" key="menu">
              <div className="name">
                <Link to="/"><h1>Greg Simsic</h1></Link>
              </div>
              <div className="page">
                <h1>/{location.pathname.split('/')[1]}</h1>
              </div>
              <div className="m-nav">
                <div className="m-navWrapper">
                  <span><NavLink to="/sequence" activeClassName="active" onClick={this.props.closeMenu}>Sequence</NavLink></span>
                  <span><NavLink to="/index" activeClassName="active" onClick={this.props.closeMenu}>Index</NavLink></span>
                  <span><NavLink to="/context" activeClassName="active" onClick={this.props.closeMenu}>Context</NavLink></span>
                  <span><NavLink to="/prelude" activeClassName="active" onClick={this.props.closeMenu}>Prelude</NavLink></span>
                  <span><NavLink to="/info" activeClassName="active" onClick={this.props.closeMenu}>Info</NavLink></span>
                </div>
              </div>
              <Route exact path={["/sequence","/sequence/:number"]} render={(props) =>
                <SlideNav
                  onNextClick={this.nextSlide}
                  onPrevClick={this.prevSlide}
                />
              }
              />
              <MobileNavToggler />
            </header>
            }
          />

          <Route render={({ location, history }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.pathname.split("/")[1] || "/"}
                classNames="fade"
                timeout={300}
                appear={true}
              >
                <div className="stable">
                  <Switch key={location.key} location={location}>

                    <Route exact path={["/sequence","/sequence/:number"]} render={(props) =>
                        <Slider
                          ref={this.slider}
                          match={props.match.params.number}
                          options={{
                            fade: true,
                            speed: 0
                          }}
                          slidesPagePath="/sequence/"
                          comps={this.props.comps}
                        />
                    }
                    />

                    <Route exact path={["/context","/context/:number"]} render={(props) =>
                        <Slider
                          ref={this.slider}
                          match={props.match.params.number}
                          options={{
                            fade: true,
                            speed: 0
                          }}
                          slidesPagePath="/context/"
                          comps={this.props.context}
                        />
                    }
                    />

                    <Route path="/images" component={Index} />
                    <Route path="/list" component={List} />
                    <Route path="/info" component={Info} />
                    <Route path="/prelude" component={Prelude} />
                    <Route exact path="/index" render={(props) =>
                      <Sets
                        slidesPagePath="/sequence/"
                      />
                    }
                    />
                    <Route exact path="/" render={(props) =>
                        <Redirect to='/sequence' />
                    }
                    />

                    {/* handle 404s */}
                    <Route path="*" render={() =>
                      <Redirect to="/sequence"/>
                    }
                    />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
            )} />

        </div>
      </Router>
    );
  }
}

App.propTypes = {
  fetchItems: PropTypes.func.isRequired,
  fetchContext: PropTypes.func.isRequired,
  comps: PropTypes.array.isRequired,
  context: PropTypes.array.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

// https://github.com/reactjs/react-transition-group/issues/296
const RedirectCheck = ({ action, children }) =>
  action === 'REPLACE' ? null : children

const mapStateToProps = (state, ownProps) => {
  return {
    comps: state.items,
    context: state.context,
    hasErrored: state.itemsHasErrored,
    isLoading: state.itemsIsLoading,
    currentSlideIndex: 0,
    menuOpen: state.menuOpen
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContext: (url) => dispatch(contextFetchData(url)),
    fetchItems: (url) => dispatch(itemsFetchData(url)),
    closeMenu: () => {
      dispatch(menuToggled(true))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
