import React, { Component } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import './SlideNav.scss'

// https://react-slick.neostack.com/docs/example/custom-arrows

class SlideNav extends Component {

  render () {
    return (
      <div className="buttons">
        <button onClick={this.props.onPrevClick}><MdArrowBack/></button>
        <button onClick={this.props.onNextClick}><MdArrowForward/></button>
      </div>
    )
  }
}

export default SlideNav;