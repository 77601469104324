import React, { Component } from 'react';
import './Info.scss';

class Info extends Component {

  render () {
    return (
      <div className="m-info__wrapper">
        <p><a href="mailto:greg@gregsimsic.com" target="_blank" rel="noopener noreferrer">greg@gregsimsic.com</a></p>
        <p>b. Lorain, Ohio</p>
        <p>MFA: Hunter College</p>
        <p>Lives and works in Brooklyn</p>
      </div>
    )
  }
}

export default Info;
