import React, { Component } from 'react';
import { connect } from 'react-redux';

class List extends Component {

  render () {
    return (
      <div style={wrapperStyle}>

        <ul style={ulStyle}>
        {this.props.comps.map( (comp) => {
          return (
            <li>{comp.id}</li>
          )
        })}
        </ul>

      </div>
    )
  }
}

const wrapperStyle = {
  padding: '80px 30px 50px'
};

const ulStyle = {
  width: 80,
  listStyle: 'none',
  height: '100vh',
  columnCount: 2
};

const mapStateToProps = (state) => {
  return {
    comps: state.items,
    hasErrored: state.itemsHasErrored,
    isLoading: state.itemsIsLoading
  }
}

export default connect(mapStateToProps, null, null, { withRef: true })(List);