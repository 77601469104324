import React, { Component } from 'react';
import './Prelude.scss';
import '../../styles/_shared.scss'

class Prelude extends Component {

  render () {
    return (
      <div className="m-prelude__wrapper">
        <div className="m-videoWrapperOuter">
        <div className="m-videoWrapper">
          <video src="assets/Video1-excerpt.mp4" poster="assets/poster.png" controls></video>
        </div>
        </div>
        <div className="m-caption">Untitled (excerpt), 1999</div>
      </div>
    )
  }
}

export default Prelude;