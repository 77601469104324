import React, { Component } from 'react';
import { throttle } from 'lodash';

class OrientationState extends Component {

  constructor(props) {
    super(props)

    this.wrapper = React.createRef();

    this.state = {
      orientationClass: 's-orientation-landscape'
    }

    this.setClass = this.setClass.bind(this);

  }

  onResize = throttle(this.setClass, 200).bind(this);

  componentDidMount() {

    console.log(this.wrapper);

    // setTimeout( () => {

      if(this.props.target) {
        this.target = this.wrapper.current.querySelector(this.props.target);
      } else {
        this.target = this.wrapper.current;
      }
      
      console.log(this.target);

      if (!this.target) { return }

      this.setClass();
      window.addEventListener("resize", this.onResize);

    // }, 0)
    

  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  setClass() {

    let ratio = this.target.offsetWidth / this.target.offsetHeight;

    let orientationSuffix = (ratio > 1) ? 'landscape' : 'portrait';

    this.setState({
      orientationClass: 's-orientation-' + orientationSuffix
    })

  }

  render () {
    return (
      <div className={this.state.orientationClass} ref={this.wrapper}>
        {this.props.children}
      </div>
    )
  }
}

export default OrientationState;
